.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
  }
  
  .grid-item {
    background-color: #fff; /* Set background to white */
    color: #4299e1; /* Adjusted color for readability */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.375rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Aligns content and button at opposite ends */
  }
  
  .product-logo {
    height: 50px;
    width: auto;
    margin: 0 auto; /* Centers the logo */
  }
  
  .grid-item h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0.5rem 0;
  }
  
  .grid-item p.price {
    font-size: 1.125rem;
    font-weight: bold;
    margin: 0.5rem 0;
    color: #4c1d95; /* bg-violet-900 * /
  }
  
  .grid-item ul {
    list-style-type: none;
    padding: 0;
    margin: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center aligns list items */
  }
  
  .grid-item ul li {
    font-size: 0.75rem; /* Smaller font size for features */
    background-color: #e5e7eb;
    color: #4c1d95; /* bg-violet-900 */
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    margin: 0.25rem;
  }
  
  .grid-item button {
    background-color: #4c1d95; /* bg-violet-900 */
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    align-self: center; /* Center aligns the button */
    width: 100%; /* Ensures the button stretches to fill container */
    font-weight: bold;
  }
  
  .grid-item button:hover {
    background-color: #2b6cb0;
    color: white;
  }
  