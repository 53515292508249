/* Basic resets */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .tickets-container {
    padding: 1rem;
    background-color: #f8f9fa;
  }
  
  .tickets-container h1 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #343a40;
  }
  
  .tickets-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  
  .ticket-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
    max-width: 400px;
  }
  
  .ticket-item h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #007bff;
  }
  
  .ticket-item p {
    margin: 0.5rem 0;
    color: #495057;
  }
  