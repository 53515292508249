.login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .login-container {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    text-align: center;
  }
  
  .login-image {
    width: 100px;
    height: auto;
    margin: 0 auto 20px;
  }
  
  .login-box {
    padding: 20px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .phone-input-container {
    display: flex;
    gap: 10px;
  }
  
  .country-code {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .phone-input {
    flex: 1;
  }
  
  .send-code-button {
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .send-code-button.disabled,
  .login-button.disabled,
  .google-button.disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .login-button,
  .google-button {
    margin-top: 20px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .google-button {
    background: #4285f4;
  }
  
  .separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .separator .line {
    flex: 1;
    height: 1px;
    background: #ddd;
  }
  
  .separator .text {
    margin: 0 10px;
  }
  
  .google-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
/* Ensure this is added to your Login.css */

.login-form .phone-input-container {
  display: flex;
  align-items: center;
}

.phone-input {
  width: 100%;
}
