/* Basic resets */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Header styles */
header {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

header .logo img {
    height: 40px;
}

header nav {
    display: flex;
    gap: 1rem;
}

header nav .nav-link {
    color: #333;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px; /* Adjusted to a more typical size */
}

header nav .nav-link:hover {
    color: #007bff;
}

header .auth-buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

header .auth-buttons .discord-logo {
    height: 20px;
    cursor: pointer;
}

header .auth-buttons .login-button {
    background-color: green; /* Change to green */
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s;
}

header .auth-buttons .logout-button {
    background-color: lightgray; /* Change to light gray */
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s;
}

header .auth-buttons .login-button:hover {
    background-color: darkgreen; /* Darker green on hover */
}

header .auth-buttons .logout-button:hover {
    background-color: gray; /* Darker gray on hover */
}

/* Ensure the main content is not hidden behind the fixed header */
body {
    padding-top: 80px; /* Adjust based on header height */
}
