/* Basic resets */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .plans-container {
    padding: 1rem;
    background-color: #f8f9fa;
  }
  
  .plans-container h1 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #343a40;
  }
  
  .plans-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  
  .plan-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
    max-width: 300px;
    text-align: center;
  }
  
  .plan-item h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #007bff;
  }
  
  .plan-item p {
    margin: 0.5rem 0;
    color: #495057;
  }
  
  .plan-item button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s;
  }
  
  .plan-item button:hover {
    background-color: #0056b3;
  }
  