.product-details-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  text-align: center;
}

.product-name {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.payment-options {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.payment-option {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  transition: background-color 0.3s, color 0.3s;
}

.payment-option.selected {
  background-color: #2b6cb0;
  color: #ffffff;
}

.payment-option span {
  display: block;
}

.final-price {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.payment-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

#paypal-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.crypto-wallets {
  margin-top: 3rem; /* Increased spacing */
  text-align: center; /* Align text to the left */
}

.crypto-wallets p {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.crypto-wallets span {
  font-weight: bold; /* Bold the labels */
}

.crypto-wallets .highlighted-text {
  color: red;
}

.product-logo-container {
  margin-top: 2rem;
  text-align: center;
}

.product-logo {
  max-width: 200px;
}

.copy-icon {
  cursor: pointer;
  margin-left: 0.5rem;
}
