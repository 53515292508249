/* Basic resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer-container {
  background-color: #4c1d95;
  color: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.footer-left, .footer-middle, .footer-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  height: 50px;
}

.footer-description {
  margin-top: 1rem;
  text-align: center;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-heading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.footer-link {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  text-decoration: underline;
}

.footer-link:hover {
  color: #007bff;
}

.footer-social-icons {
  display: flex;
  gap: 1rem;
}

.discord-icon {
  height: 40px;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}
