/* About.css */

.about-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
  
  .about-header h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .about-header p {
    font-size: 1.2rem;
    color: #777;
    margin-bottom: 2rem;
  }
  
  .about-content p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
    margin: 1rem 0;
    text-align: center;
  }
  
  .about-footer {
    margin-top: 2rem;
  }
  
  .about-footer .btn-discord,
  .about-footer .btn-email {
    display: inline-block;
    margin: 0 1rem;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .about-footer .btn-discord {
    background-color: #7289da;
  }
  
  .about-footer .btn-discord:hover {
    background-color: #5a6fb2;
  }
  
  .about-footer .btn-email {
    background-color: #f39c12;
  }
  
  .about-footer .btn-email:hover {
    background-color: #d9880b;
  }
  