/* Basic resets */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .orders-container {
    padding: 1rem;
    background-color: #f8f9fa;
  }
  
  .orders-container h1 {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #343a40;
  }
  
  .orders-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
  
  .order-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
  }
  
  .order-details {
    margin-top: 1rem;
  }
  
  .order-details h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #007bff;
  }
  
  .order-details p {
    margin: 0.5rem 0;
    color: #495057;
  }
  
  .view-ticket-button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s;
  }
  
  .view-ticket-button:hover {
    background-color: #0056b3;
  }
  
  .ticket-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .ticket-content {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
  }
  
  .ticket-content button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s;
  }
  
  .ticket-content button:hover {
    background-color: #0056b3;
  }
  
  .footer-text {
    text-align: center;
    color: red;
    margin-top: 2rem;
    font-size: 1rem;
  }
  
  .footer-text a {
    color: red;
    text-decoration: underline;
  }
  
  @media (min-width: 768px) {
    .order-item {
      flex-direction: row;
      align-items: center;
    }
  
    .order-details {
      margin-top: 0;
      margin-left: 1rem;
    }
  }
  